import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { REGISTER_STREAMER_URL } from "../../links";
import mobilePreview from "../../images/starfarm-mobile-preview.png";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import midSection from "../../images/midTransparent.png";

interface IListenerSectionProps {}

const ListenerSection: FC<IListenerSectionProps> = (props) => {
  const theme = useTheme();

  return (
    <section
      style={{
        position: "relative",
        backgroundColor: "transparent",
        zIndex: 999,
      }}
      className="parallax-curtain"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        zIndex={2}
        height="100%"
      >
        <Container style={{ height: "100%" }}>
          <Grid
            style={{ height: "100%", paddingTop: 64 }}
            justify="space-between"
            alignItems="center"
            container={true}
          >
            <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
              <img
                src={mobilePreview}
                style={{ maxHeight: 600, maxWidth: "80vw" }}
                height="auto"
                width="auto"
                alt=""
              />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box display="flex" alignItems="flex-end">
                <Typography
                  style={{
                    letterSpacing: -24,
                    fontWeight: 500,
                    fontSize: 180,
                    color: theme.palette.primary.main,
                    marginRight: 26,
                  }}
                >
                  //
                </Typography>
                <Typography
                  style={{
                    letterSpacing: -5,
                    fontWeight: 500,
                    fontSize: 110,
                    lineHeight: "85px",
                  }}
                  gutterBottom
                  variant="h1"
                >
                  as the listener
                  <span style={{ color: "#4155ff", fontFamily: "cursive" }}>
                    .
                  </span>
                </Typography>
              </Box>

              <Typography variant="h4" color="textSecondary">
                A place to enjoy, discover and support artists. Listen on the
                web or on mobile.
              </Typography>

              <Box display="flex" justifyContent="center">
                <Button
                  component="a"
                  href={REGISTER_STREAMER_URL}
                  style={{ marginTop: 64 }}
                  variant="contained"
                  color="secondary"
                >
                  start listening
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <img
        src={midSection}
        style={{
          minHeight: "120vh",
          position: "absolute",
          top: 0,
          left: 0,
          maxHeight: "100vh",
        }}
        height="auto"
        width="100%"
        alt=""
      />
    </section>
  );
};

export default ListenerSection;
