import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { REGISTER_ARTIST_URL } from "../../links";
import Button from "@material-ui/core/Button";
import dashboardPreview from "../../images/dashboard2.png";

interface IArtistSectionProps {}

const ArtistSection: FC<IArtistSectionProps> = (props) => {
	return (
		<Box width="100%" height="100%">
			<Container
				style={{
					height: "100%",
					maxWidth: "100vw",
					overflow: "hidden",
				}}
			>
				<Grid
					style={{ height: "100%", paddingTop: 64, paddingLeft: "5%" }}
					justify="space-between"
					alignItems="center"
					container={true}
				>
					<Grid item={true} xs={12} sm={12} md={12} lg={4} xl={4}>
						<Box display="flex" alignItems="flex-end">
							<Typography
								style={{
									letterSpacing: -24,
									marginRight: 26,
									fontWeight: 500,
									fontSize: 180,
									color: "#B04ED5",
								}}
							>
								//
							</Typography>
							<Typography
								style={{
									letterSpacing: -5,
									fontWeight: 500,
									fontSize: 110,
									lineHeight: "85px",
									color: "#0FBED3",
								}}
								gutterBottom
								variant="h1"
							>
								for the artist
								<span style={{ color: "#B04ED5", fontFamily: "cursive" }}>
									.
								</span>
							</Typography>
						</Box>

						<Typography
							style={{
								marginBottom: 20,
							}}
							color="textSecondary"
						>
							What makes StarFarm a game changer for the music industry? Unlike
							traditional music streaming platforms, StarFarm is backed by
							blockchain technology. What’s blockchain technology you might ask
							and why does it matter? Well, it matters because the music
							industry has historically not been very good to artists when it
							comes to paying them. Blockchain technology completely disrupts
							the current system and simplifies it by being completely
							transparent and immutable. With StarFarm, artists get paid for
							each and every play, get to pick the price per play, and get paid
							instantly. It’s that simple.
						</Typography>

						<Typography color="textSecondary">
							StarFarm. We Grow Stars.
						</Typography>

						<Box display="flex" justifyContent="center">
							<Button
								component="a"
								href={REGISTER_ARTIST_URL}
								style={{ marginTop: 64 }}
								variant="contained"
								color="secondary"
							>
								start earning
							</Button>
						</Box>
					</Grid>

					<Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
						<img
							src={dashboardPreview}
							style={{ maxHeight: 600, maxWidth: "80vw" }}
							height="auto"
							width="auto"
							alt=""
						/>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default ArtistSection;
