import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { REGISTER_URL } from "../../links";
import starfarmLogoUri from "../../images/starfarmlogo.png";
import Box from "@material-ui/core/Box";

interface IHeroProps {}

const Hero: FC<IHeroProps> = (props) => {
	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				flexDirection: "column",
			}}
		>
			<Box
				position="absolute"
				top={16}
				width="100%"
				justifyContent="center"
				display="flex"
				alignItems="center"
			>
				<img width={35} height={35} src={starfarmLogoUri} alt="logo" />
				<Typography variant="h5">StarFarm</Typography>
			</Box>

			<Typography
				style={{
					letterSpacing: -5,
					fontWeight: 500,
					fontSize: 110,
					height: "95px",
				}}
				align="center"
				variant="h1"
			>
				Lose yourself
			</Typography>

			<Typography
				style={{
					letterSpacing: -5,
					fontWeight: 500,
					fontSize: 110,
					height: "95px",
				}}
				align="center"
				variant="h1"
			>
				in the music
			</Typography>

			<Box display="flex" alignItems="flex-end">
				<Typography
					style={{
						letterSpacing: -5,
						fontWeight: 500,
						fontSize: 110,
						marginRight: 20,
					}}
					gutterBottom
					align="center"
					variant="h1"
				>
					you
				</Typography>

				<Typography
					style={{
						fontWeight: 500,
						fontSize: 110,
					}}
					gutterBottom
					align="center"
					variant="h1"
				>
					f
				</Typography>

				<Typography
					style={{
						letterSpacing: -5,
						fontWeight: 500,
						fontSize: 110,
					}}
					gutterBottom
					align="center"
					variant="h1"
				>
					ind.
				</Typography>
			</Box>

			<Button
				component="a"
				href={REGISTER_URL}
				variant="contained"
				color="secondary"
			>
				sign-up today
			</Button>
		</div>
	);
};

export default Hero;
