import React, { FC } from "react";

import "./App.css";
import dashboardPreview from "./images/dashboard2.png";
import { REGISTER_ARTIST_URL } from "./links";

import Hero from "./components/Mobile/Hero";
import ListenerSection from "./components/Mobile/ListenerSection";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Footer from "./components/Mobile/Footer";
import IconButton from "@material-ui/core/IconButton";
import Help from "@material-ui/icons/Help";

interface IMobileProps {}

const Mobile: FC<IMobileProps> = (props) => {
	return (
		<div style={{ position: "relative" }}>
			<Box
				display="flex"
				alignItems="center"
				position="fixed"
				zIndex={99999}
				bottom={4}
				right={0}
			>
				<IconButton style={{ color: "#8f7b1b" }}>
					<Help style={{ width: 17, height: "auto" }} />
				</IconButton>
			</Box>

			<Hero />

			<ListenerSection />

			<section
				style={{
					background: "linear-gradient(rgb(2, 22, 43), rgb(27 28 77) 89%)",
				}}
			>
				<Box display="flex" justifyContent="center" mb={4}>
					<img
						src={dashboardPreview}
						style={{ maxWidth: "80vw" }}
						height="auto"
						width="auto"
						alt="starfarm artist dashboard"
					/>
				</Box>

				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					justifyContent="center"
					px={2}
				>
					<Typography
						style={{
							letterSpacing: -5,
							fontWeight: 500,
							lineHeight: "85px",
							color: "#0FBED3",
						}}
						gutterBottom
						variant="h1"
					>
						for the artist
						<span style={{ color: "#4155ff", fontFamily: "cursive" }}>.</span>
					</Typography>
					<Typography
						style={{ marginBottom: 20, maxWidth: "80%" }}
						align="center"
						variant="body2"
						color="textSecondary"
					>
						What makes StarFarm a game changer for the music industry? Unlike
						traditional music streaming platforms, StarFarm is backed by
						blockchain technology. What’s blockchain technology you might ask
						and why does it matter? Well, it matters because the music industry
						has historically not been very good to artists when it comes to
						paying them. Blockchain technology completely disrupts the current
						system and simplifies it by being completely transparent and
						immutable. With StarFarm, artists get paid for each and every play,
						get to pick the price per play, and get paid instantly. It’s that
						simple.
					</Typography>

					<Typography align="center" variant="body2" color="textSecondary">
						StarFarm. We Grow Stars.
					</Typography>

					<Button
						component="a"
						href={REGISTER_ARTIST_URL}
						style={{ marginTop: 32 }}
						variant="contained"
						color="secondary"
					>
						start earning
					</Button>
				</Box>
			</section>

			<Footer />
		</div>
	);
};

export default Mobile;
