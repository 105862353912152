import React, { FC } from "react";

import { REGISTER_STREAMER_URL } from "../../links";
import mobilePreview from "../..//images/starfarm-mobile-preview.png";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

interface IListenerSectionProps {}

const ListenerSection: FC<IListenerSectionProps> = (props) => {
  return (
    <section
      style={{
        height: "100vh",
        backgroundColor: "#02162b",
      }}
    >
      <Box display="flex" justifyContent="center" mb={4}>
        <img
          src={mobilePreview}
          style={{ maxHeight: "40vh", marginLeft: -10 }}
          height="auto"
          width="auto"
          alt="starfarm app"
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        px={2}
      >
        <Box display="flex" alignItems="flex-end">
          <Typography
            style={{
              letterSpacing: -5,
              fontWeight: 500,
              lineHeight: "85px",
            }}
            variant="h1"
          >
            as the listener
            <span style={{ color: "#4155ff", fontFamily: "cursive" }}>.</span>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" maxWidth={300}>
          <Typography variant="body2" align="center" color="textSecondary">
            A place to enjoy, discover and support artists. Listen on the web or
            on mobile.
          </Typography>
        </Box>

        <Button
          component="a"
          href={REGISTER_STREAMER_URL}
          style={{ marginTop: 32 }}
          variant="contained"
          color="secondary"
        >
          start listening
        </Button>
      </Box>
    </section>
  );
};

export default ListenerSection;
