import React from 'react'
import "./App.css"
import Navigation from "./components/Desktop/Navigation";
import starfarmLogoUri from "./images/starfarmlogo.png";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const ContactUs = () => {
    window.scrollTo(0, 0)
    return (
        <>
            <Link to="/">
                <Box
                    position="absolute"
                    top={16}
                    width="100%"
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                    zIndex={999999}
                >
                    <img width={35} height={35} src={starfarmLogoUri} alt="logo" />
                    <Typography variant="h5">StarFarm</Typography>
                </Box>
            </Link>
            <Navigation />
            <div className='contactContainer'>
                <div className='containerBox'>
                    <h1>
                        For Further Queries and Complains
                    </h1>
                    <p>
                        Please contact the StarFarm Admin
                    </p>
                    <p className='email'>
                        support@starfarm.com
                    </p>
                </div>
            </div>
        </>
    )

}

export default ContactUs



