import React, { FC } from "react";

interface SocialLinkProps {
  href?: string;
  imgSrc?: string;
}

const SocialLink: FC<SocialLinkProps> = ({ href = "", imgSrc = "" }) => (
  <a
    style={{ marginLeft: 16 }}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img height={28} width={28} src={imgSrc} alt="starfarm-twitter" />
  </a>
);

export default SocialLink;
