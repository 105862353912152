export const LOGIN_URL = "http://app.starfarm.com/login";

export const REGISTER_URL = "http://app.starfarm.com/registration";

export const REGISTER_STREAMER_URL = "http://app.starfarm.com/registration";

export const REGISTER_ARTIST_URL = "http://app.starfarm.com/registration";

export const twitterHref = "https://twitter.com/StarFarm10";

export const instagramHref = "https://www.instagram.com/starfarmmusic/";

export const facebookHref = "https://www.facebook.com/starfarmmusic";
