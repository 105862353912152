import React, { FC } from "react";

import "./App.css";
import hero from "./images/hero.png";
import Footer from "./components/Desktop/Footer";
import ArtistSection from "./components/Desktop/ArtistSection";
import Navigation from "./components/Desktop/Navigation";
import Hero from "./components/Desktop/Hero";
import ListenerSection from "./components/Desktop/ListenerSection";


interface IDesktopProps { }

const Desktop: FC<IDesktopProps> = (props) => {
  return (
    <div>
        <section
          style={{ backgroundImage: `url(${hero})` }}
          className="top-parallax"
        >
          <Navigation />

          <Hero />

          <ListenerSection />

          <section
            style={{
              paddingTop: "25vh",
              background:
                "linear-gradient(0deg, rgba(90,89,89,1) 0%, rgba(8,26,47,1) 37%, rgba(5,24,41,1) 67%, rgba(2,22,43,1) 94%)",
            }}
          >
            <ArtistSection />

            <Footer />
          </section>
        </section>
      
    </div>
  );
};

export default Desktop;
