import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#29C2BA",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fd4060",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: 50,
        padding: "6px 24px",
        minWidth: 200,
      },
    },
  },
});

const themeWithResponsiveFonts = responsiveFontSizes(theme);

export default themeWithResponsiveFonts;
