import React from "react"
import "./App.css"
import Navigation from "./components/Desktop/Navigation";
import starfarmLogoUri from "./images/starfarmlogo.png";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";


const PrivacyPolicy = () => {
    window.scrollTo(0, 0)
    return (
        <>
            <Link to="/">
                <Box
                    position="absolute"
                    top={16}
                    width="100%"
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                >
                    <img width={35} height={35} src={starfarmLogoUri} alt="logo" />
                    <Typography variant="h5">StarFarm</Typography>
                </Box>
            </Link>
            <Navigation />
            <div className="termsContainer">
                <h2>

                    StarFarm Privacy Policy
                </h2>
                <p>

                    Effective as of March 26, 2021
                </p>
                <p>

                    Welcome to StarFarm! Thank you for giving us the opportunity to be of service.
                    At StarFarm, we want to give you the best possible experience to ensure that you
                    enjoy our service. To do this we need to understand your streaming habits so we
                    can deliver an exceptional and personalized service specifically for you. Your
                    privacy and the security of your personal data is, and will always be, enormously
                    important to us. So, we want to transparently explain how and why we gather,
                    store, share and use your personal data
                    BY VISITING, ACCESSING, OR USING THE SERVICES, YOU ACCEPT THE
                    PRACTICES IN THIS PRIVACY POLICY. PLEASE READ THIS POLICY AND
                    STARFARM’S TERMS AND CONDITIONS OF USE CAREFULLY.
                </p>
                <h3 className="termsHeading">

                    1. INTRODUCTION
                </h3>
                <p>

                    This Privacy Policy together with the Terms and Conditions of Use set out how
                    StarFarm, LLC. (“STARFARM”) processes any personal data that we collect
                    about you, or which you provide to us, when you access and use the StarFarm
                    music streaming service, and mobile device applications or associated
                    websites, social media pages, and our emails, (“Services”). Our policy is to
                    process your personal data at all times in accordance with applicable data
                    protection laws and regulations. By “personal data,” we mean any information
                    that identifies, relates to, or describes, directly or indirectly, a particular
                    individual.
                    If you have questions, comments or requests about this Privacy Policy please
                    contact us by sending an email to support@starfarm.com.
                </p>
                <h3 className="termsHeading">

                    2. CHILDREN AND PRIVACY
                </h3>
                <p>

                    The Services and their content are not directed at children under the age of 13.
                    StarFarm does not knowingly collect or solicit any personal data from anyone
                    under the age of 13 or knowingly allow such persons to register for the Services.
                    In the event that we learn that we have collected personal data from a child
                    under age 13 without parental consent, we will delete that information as quickly
                    as possible. If you believe that we might have any personal data from or about a
                    child under 13, please notify us immediately by sending an email
                    to support@StarFarm.com.
                </p>
                <h3 className="termsHeading">

                    3. COLLECTION OF PERSONAL DATA
                </h3>
                <p>

                    We collect the following categories of personal data:
                    <ul className="outerList">
                        <li>
                            a) Information you provide us directly. When you register as a user for the
                            online Services (through a paid subscription or trial offer) and/or use the
                            Services (such as when you respond to surveys) you provide us with certain
                            information that we store. This may include:
                            <ul className="innerList">
                                <li>
                                    personal identifiers (such as your name, username, social media name,
                                    address, email address, password, and phone number)
                                </li>
                                <li>
                                    device identifiers (such as your carrier identifier)
                                </li>
                                <li>
                                    personal characteristics (such as your date of birth, gender, and the
                                    language you speak)
                                </li>
                                <li>
                                    financial information (such as payment card information and PayPal and
                                    Apple transaction information)
                                </li>
                                <li>
                                    audio and visual information (such as account photo, other photos, and
                                    other materials)
                                </li>
                                <li>
                                    geolocation data and other metadata (such as content creation date,
                                    formatting information, and location information (i.e., geotags))
                                </li>
                                <li>
                                    commercial information (such as download and purchase history for
                                    subscriptions, events, and concerts
                                </li>
                                <li>
                                    communications between you and StarFarm (such as comments on the
                                    Services (including social media pages) and customer service requests)
                                </li>
                                <li>
                                    inferences from any of the above categories about your preferences
                                </li>
                            </ul>
                        </li>
                        <li>

                            b) Information collected from third parties. We may receive information about
                            you from third parties who may have collected and transferred your personal
                            data to us in accordance with their own privacy policies and/or terms of use.
                            This may include personal identifiers (such as your name, address, email
                            address, and phone number) and personal characteristics (such as your data of
                            birth and gender). Examples of such third parties include social media networks
                            such as Facebook or Twitter. Where we receive such information from such third
                            parties, we may combine it with the other personal data that we have collected
                            about you as set out herein and use such information either alone or in
                            combination with the other personal data we hold for the purposes outlined in
                            this Privacy Policy.
                        </li>
                        <li>
                            c) Automatic information collection. When you use the online Services, we and
                            service providers and trusted partners use cookies, log data, and similar
                            technology to automatically record personal identifiers (such as your IP address
                            and device identifiers), information about your device and browser (such as your
                            browser type, locale preferences, and mobile carrier) and information
                            concerning your online Services usage activity (such as favorites, playlists
                            created, tracks played and downloaded, activity in the StarFarm applications,
                            offlined tracks, date and time of listening, clicks and views of content and
                            pages, product ID, time of log-in, and what version of the Services you use,
                            email opens, clicks, and forwards, the link you used to reach them, and the
                            content and advertising displayed on them and our content and advertising on
                            other websites and online services). If you access the Services via a handheld
                            device, we also store a device identifier that uniquely identifies the device.
                            We and our service providers and trusted partners use this information
                            <ul>
                                <li>
                                    (i) to
                                    provide, tailor, and personalize the Services to you;
                                </li>
                                <li>

                                    (ii) to enable certain features
                                    on the Services;
                                </li>
                                <li>

                                    (iii) for service improvement purposes; and
                                </li>
                                <li>

                                    (iv) for marketing
                                    and advertising purposes (as further described herein).
                                </li>

                            </ul>
                            We may also use internal
                            or third-party analytics tools to help us measure traffic and usage trends for the
                            Services that assist us in providing and improving the Services.
                            We permit service providers and trusted partners to track visitors to the Services
                            and the activities of those visitors on the Services over time, and they may track
                            those visitors across other websites and online services over time, if those
                            websites and apps also use the same partners. In these situations, you are using
                            and directing us to intentionally disclose personal information (including
                            personal identifiers) to those service providers and trusted partners so they can
                            perform those services.
                            The Services may use both temporary (“session”) and persistent cookies. The
                            Help feature on most browsers will tell you how to prevent your browser from
                            accepting new cookies, how to have the browser notify you when you receive a
                            new cookie, or how to disable cookies. This may, however, impact the
                            functionality of the Services.
                            You can see a list of cookies and similar technology in the Cookies List below.
                            We currently use Google Analytics from Google Inc. to create anonymous usage
                            statistics. You may opt-out of Google Analytics by installing a plug-in to your
                            browser. A plug-in for the most common browsers can be found here: https://
                            tools.google.com/dlpage/gaoptout.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    4. USE OF PERSONAL DATA
                </h3>
                <p>
                    In addition to some of the specific uses of personal data we separately describe
                    in this Policy, we use personal data for our business and commercial purposes
                    including in the following situations:
                    <ul className="innerList">
                        <li>
                            Your personal identifiers and financial information to process payments
                            for use of the Services
                        </li>
                        <li>
                            Your personal identifiers (including online identifiers), geolocation data,
                            and online Services activity information to help you efficiently access
                            your information and features of the Services
                        </li>
                        <li>
                            Your device identifiers, such as your carrier code, so that we can provide
                            you with the applicable trial offers or services in a bundled package with
                            your carrier service
                        </li>
                        <li>
                            Your personal identifiers (including online identifiers) and online Services
                            activity information to remember information so you will not have to reenter
                            it during your visit or the next time you visit the Services
                        </li>
                        <li>
                            Your personal identifiers, personal characteristics, and commercial
                            information to provide personalized content and information to you and
                            others, which could include online ads or other forms of marketing
                        </li>

                        <li>
                            Your personal identifiers (including online identifiers) and online Services
                            activity information to provide, improve, test, and monitor the
                            effectiveness of our Services
                        </li>
                        <li>
                            Your personal identifiers and commercial information to calculate royalty
                            payments for content rights holders
                        </li>
                        <li>
                            Your personal identifiers (including online identifiers), personal
                            characteristics, commercial information, and inferences on your
                            preferences, to develop and test new products and features
                        </li>

                        <li>
                            Your personal identifiers (including online identifiers) and personal
                            characteristics to monitor metrics such as total number of visitors, traffic,
                            and demographic patterns
                        </li>
                        <li>
                            Your personal identifiers (including online identifiers) to diagnose or fix
                            technology problems
                        </li>
                        <li>
                            Your personal identifiers (including online identifiers), personal
                            characteristics, financial information, commercial information, and
                            inferences of your preferences to detect, investigate and take all steps
                            necessary to address misuse of the Services
                            We de-identify and/or aggregate personal data we receive and may use and
                            disclose it for any business or commercial purpose.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    5. SHARING INFORMATION WITH OTHERS
                </h3>
                <p>

                    We may share any of the categories of personal data listed above with our
                    affiliates on whom we rely to provide the Services and for the purposes outlined
                    in this Privacy Policy. We may also share any of the categories of personal data
                    listed above with third parties other than our affiliates for our business and
                    commercial purposes including in the following situations:
                    <ul className="outerList">
                        <li>
                            a) Parties with whom we may share your personal data.
                        </li>
                        <ul className="innerList">
                            <li>
                                Content Rights Holders. The Services allow you to listen to and view
                                content from third party content rights holders. StarFarm shares
                                information with the rights holders that license this content to StarFarm.
                                The data that StarFarm shares with the rights holders is in a de-identified
                                format that does not identify you directly, unless you opt into the sharing
                                of your personal data.
                            </li>
                            <li>
                                Service Providers. We may share and use your information (including but
                                not limited to, information from cookies, log files, metadata and usage
                                data) with third party organizations that help us provide the Services to
                                you, including telecommunications companies with whom you have a
                                contractual relationship and through which you may receive access to the
                                Services (for example through a bundled package), and payment
                                processors (“Service Providers”). Our Service Providers will be given
                                access to your information as is reasonably necessary to provide the
                                Services under reasonable confidentiality terms.
                            </li>
                            <li>
                                Third Parties/Trusted Partners Providing Services on Our Behalf. We may
                                share your personal data with trusted partners that provide you and us
                                with services, such as those that process transactions and payments,
                                process bundled transactions (e.g. with your telecommunications
                                provider), and facilitating and publishing surveys and fulfill orders. In
                                these situations, you are using and directing us to intentionally disclose
                                personal data (including personal identifiers and financial information) so
                                we can fulfill your requests.
                            </li>
                            <li>
                                Marketing and Advertising. We may share online Services usage
                                information (e.g. the number of plays of a particular song or playlist by all
                                users) with third party advertising partners in order to allow third party ad
                                networks to, among other things, deliver targeted advertisements that
                                they believe will be of most interest to you. We may also deliver targeted
                                emails and other communications to you concerning such advertisers,
                                their products or services and/or events sponsored or hosted by such
                                advertisers. We may remove parts of data that can identify you and share
                                anonymized data with other parties. We may also combine your
                                information with other information in a way that it is no longer associated
                                with you and share that aggregated information, which, in its aggregated
                                form, may include demographic data such as how many users constitute
                                a particular age group, general locations of where groups of users reside.
                            </li>
                            <li>
                                Your Financial Institution. In the event you contest or dispute
                                Subscription charges, we may share information collected in accordance
                                with this policy, as reasonable, to assist your credit card company or
                                financial institution to investigate and resolve your claim.
                            </li>
                            <li>
                                Acquisition. If we sell or buy any business or assets, we may disclose
                                your personal data to the prospective seller or buyer of such business or
                                assets and its advisors. If StarFarm or substantially all its assets are
                                acquired by or sold to a third party, your personal data may be among
                                the transferred assets.
                            </li>
                        </ul>
                        <li>
                            b) Parties with whom you may choose to share your personal data.
                            Any personal identifiers (e.g. name) or audio, electronic, visual and similar
                            information (e.g. picture) that you voluntarily share or disclose for posting to the
                            Services may become available to the public.
                        </li>
                        <li>
                            c) Responding to legal requests and preventing harm.
                            We may access, preserve and share your personal data with law enforcement,
                            the courts, our advisors, attorneys, and others who participate in the legal
                            process in response to a legal request (such as a search warrant, court order or
                            subpoena) if we have a good faith belief that the law requires us to do so. This
                            may include responding to legal requests from jurisdictions outside of the United
                            States where we have a good faith belief that the response is required by law in
                            that jurisdiction, affects users in that jurisdiction, and is consistent with
                            internationally recognized standards. We may also access, preserve and share
                            information with law enforcement, the courts, our advisors, attorneys, and
                            others who participate in the legal process when we have a good faith belief it is
                            necessary to: detect, prevent and address fraud and other illegal activity; to
                            protect StarFarm, you and others, including as part of investigations; and to
                            prevent death or imminent bodily harm. Information we receive about you may
                            be accessed, processed and retained for an extended period of time when it is
                            the subject of a legal request or obligation, governmental investigation, or
                            investigations concerning possible violations of our terms or policies, or
                            otherwise to prevent harm. By allowing for sharing and use of personal data for
                            these purposes, StarFarm is not assuming any affirmative duty to undertake
                            such actions.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    6. STORING AND SECURING PERSONAL DATA
                </h3>
                <p>
                    <ul className="outerList">
                        <li>
                            a) By registering for, accessing, visiting or otherwise using the Services, you
                            consent to the transfer of your personal data to anywhere where StarFarm or its
                            Service Providers maintain facilities, including facilities in another country.
                        </li>
                        <li>
                            b) We use commercially reasonable safeguards to help keep your personal data
                            secure and to protect against accidental loss, destruction or damage. Only a
                            limited number of personnel with a legitimate business or legal need are given
                            access to users’ personal data. However, we cannot ensure the security of any
                            information you transmit to StarFarm or guarantee that information on the
                            Services may not be accessed, used, disclosed, altered, or destroyed.
                        </li>
                        <li>
                            c) You are responsible for maintaining the secrecy of any non-public information
                            that you provide StarFarm, accessing the Services and making payments from a
                            secure computer or device, and for controlling access to emails between you
                            and StarFarm, at all times.
                        </li>
                        <li>
                            d) Information we collect or receive about you may be accessed, processed and
                            retained for an extended period of time when it is the subject of a legal request
                            or obligation, governmental investigation, or investigations concerning possible
                            violations of our terms or policies, or otherwise to prevent harm.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    7. COMMUNICATIONS, NOTICES, & CONSENT
                </h3>
                <p>

                    We may send you emails regarding matters relevant to the Services and
                    consistent with your preferences, this Privacy Policy and applicable law. Such
                    information includes receipts for payment; information about and changes to the
                    Services, including, for example, forthcoming streaming content and artist
                    information, the StarFarm newsletter, and certain revisions to the Terms and
                    Conditions of Use and/or this Policy. We may also deliver promotional offers and
                    communications on behalf of third party advertisers concerning their products or
                    services and/or events sponsored or hosted by such advertisers. We do not sell
                    or give your email address to other companies for their own marketing purposes
                    without your permission.
                    You may unsubscribe from certain email communications by clicking on the
                    “unsubscribe link” provided in such communications. You may not opt out of
                    certain important email communications (e.g. account verification, payment
                    receipts, technical and security notices) and by signing up for or using the
                    Services, you thereby agree to receive such important email communications.
                    By providing us with a telephone number for a mobile device, including a
                    number that you later convert to a mobile device number, you expressly consent
                    to receiving communications—including but not limited to prerecorded or
                    artificial voice message calls, text / SMS messages, and calls made by an
                    automatic telephone dialing system—from StarFarm at that number, about the
                    Services. This express consent applies to each such telephone number that you
                    provide to us now or in the future. Calls and messages may incur access fees
                    from your mobile service provider. You understand that you need not provide
                    this consent as a condition of purchase, or by visiting, accessing or using the
                    Services, and that you may revoke your consent at any time by
                    emailing support@StarFarm.com, or replying STOP in response to any text
                    message you receive from StarFarm.
                </p>
                <h3 className="termsHeading">

                    8. DO NOT TRACK
                </h3>
                <p>

                    The Services do not respond to Do Not Track (DNT) signals and handles all
                    personal data consistent with its Terms and Conditions of Use and this Policy.
                </p>
                <h3 className="termsHeading">

                    9. CALIFORNIA NOTICE AND PRIVACY RIGHTS
                </h3>
                <p>

                    We do not sell personal information, and we have not sold personal information
                    since January 1, 2020, the date the CCPA went into effect. We also do not rent,
                    sell, or share personal information (as defined by California Civil Code §1798.83)
                    about you that we collect on the Services with other people or unaffiliated
                    companies for their direct marketing purposes, unless we have your permission.
                    If you are a California resident under the age of 18 and you wish to remove
                    publicly available content or information posted by you on the Services, please
                    contact us at support@StarFarm.com. The removal described in the proceeding
                    sentence does not ensure complete or comprehensive removal of the content or
                    information you posted.
                    A California consumer has the following rights: to request additional information
                    about our data collection, use, disclosure, and sales practices in connection
                    with the consumer’s personal information; to request the specific personal
                    information collected about the consumer during the previous 12 months; and to
                    request the deletion of the personal information we have about the consumer. A
                    California consumer may not be discriminated against for exercising the
                    consumer’s California privacy rights.
                    To make a request, you may email us at support@StarFarm.com . In most cases,
                    you will be required to provide your name, email address, and date of birth so
                    that we can verify your request, and in some cases additional information may
                    be required.
                    Please note that, when you make a request, we may require that you provide
                    information and follow procedures so that we can verify your identity (and the
                    applicable jurisdiction). The verification steps we take may differ depending on
                    the request. Where possible, we will attempt to match the information that you
                    provide in your request to information we already have on file to verify your
                    identity. If we are able to verify your request, we will process it. If we cannot
                    verify your request, we may ask you for additional information to help us do so.
                    We will respond to your request within the 45-day time period required by
                    applicable law. However, we may not always be able to fully comply with your
                    request, and we will notify you in that event.
                    Under the CCPA, California consumers may use an authorized agent to make
                    privacy rights requests. We require the authorized agent to provide us with proof
                    of the California consumer’s written permission (for example, a power of
                    attorney) that demonstrates authorization to submit a request for the California
                    consumer. An authorized agent must follow the process described below to
                    make a request, and we will also (a) require the authorized agent to verify the
                    agent’s own identity and (b) confirm the agent’s authority with the California
                    consumer about whom the request was made.
                </p>
                <h3 className="termsHeading">

                    10. CONDITIONS OF USE AND REVISIONS TO THIS POLICY
                </h3>
                <p>

                    If you choose to visit, access, or use the Services, your visit, access and/or use
                    and any dispute over privacy is subject to this Policy and StarFarm’s Terms and
                    Conditions of Use, including limitations on damages, resolution of disputes —
                    including an agreement to arbitrate, to not proceed in court or on a class or
                    collective basis, and to not have the right to resolve disputes by a judge or a jury
                    — and application of applicable federal law and the laws of the State of Texas.
                    If we suspect misuse of the Services, we may combine the personal data we
                    have collected in accordance with this Privacy Policy with other information in
                    order to investigate the extent of the misuse and who is responsible.
                    The Services are subject to constant improvement and future changes may
                    influence how we collect, use, share, and store your personal data. This Policy
                    will be updated to reflect such changes, changes in legal framework or
                    improvements in how we handle personal data.
                    Unless stated otherwise, StarFarm’s most recent Privacy Policy applies to all
                    information that we have about you. We may e-mail periodic reminders of the
                    Privacy Policy and Terms and Conditions of Use, but you should check the
                    StarFarm website to see recent changes. Your continued visitation, access and/
                    or use of the Services after any revisions or modification to this Policy will
                    constitute acceptance of such changes.
                </p>
                <h3 className="termsHeading">

                    11. LINKS TO OTHER WEBSITES AND ONLINE SERVICES
                </h3>
                <p>

                    We are not responsible for the practices employed by any websites or services
                    linked to or from the Services including the information or content contained
                    within them. When you use a link to go from our Services to another website or
                    service, our Privacy Policy does not apply to those third party websites or
                    services. Your browsing and interaction on any third party website or service,
                    including those that have a link on our Services, are subject to that third party’s
                    own rules and policies. In addition, you understand and agree that we are not
                    responsible and do not have control over any third parties that you authorize to
                    access your personal data. If you are using a third party website or service and
                    you allow them to access your personal data you do so at your own risk.
                </p>
                <h3 className="termsHeading">

                    12. CONTACT INFORMATION
                </h3>
                <p>

                    For more information, please contact:
                    StarFarm – Customer Support
                    c/o 3723 Greenville Avenue,
                    Dallas, Texas 75206,
                    or by email at support@StarFarm.com.
                </p>
            </div>
        </>
    )
}

export default PrivacyPolicy