import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { REGISTER_URL, LOGIN_URL } from "../../links";
import starfarmLogoUri from "../../images/starfarmlogo.png";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles<Theme>((theme) => ({
	text: {
		letterSpacing: "-3px",
		wordSpacing: "4px",
		marginBottom: 64,
		[theme.breakpoints.up("md")]: {
			lineHeight: 0.9,
			maxWidth: "50%",
		},
		[theme.breakpoints.down("sm")]: {
			lineHeight: 1,
			maxWidth: "60%",
		},
		[theme.breakpoints.down("xs")]: {
			maxWidth: "70%",
		},
	},
}));
interface IHeroProps {}

const Hero: FC<IHeroProps> = (props) => {
	const classes = useStyles();

	return (
		<section
			style={{
				backgroundPosition: "bottom",
				backgroundSize: "cover",
				backgroundColor: "#02162b",
				background: "linear-gradient(rgb(27, 28, 77), rgb(2, 22, 43)  89%)",
				minHeight: 650,
			}}
		>
			<Box alignItems="center" display="flex" p={2}>
				<img
					style={{ marginRight: 2 }}
					width={20}
					height="auto"
					src={starfarmLogoUri}
					alt="logo"
				/>

				<Typography color="textSecondary" variant="body2">
					StarFarm
				</Typography>
			</Box>
			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					height: "100vh",
					flexDirection: "column",
					paddingTop: 64,
				}}
			>
				<img width={125} height="auto" src={starfarmLogoUri} alt="logo" />

				<Typography
					className={classes.text}
					gutterBottom
					align="center"
					variant="h1"
				>
					Lose yourself in the music you find.
				</Typography>

				<Button
					component="a"
					href={LOGIN_URL}
					style={{ marginBottom: 32 }}
					variant="contained"
					color="primary"
				>
					log in
				</Button>

				<Button
					component="a"
					href={REGISTER_URL}
					variant="contained"
					color="secondary"
				>
					create account
				</Button>
			</div>
		</section>
	);
};

export default Hero;
