import React from "react";

import { withWidth, WithWidth, isWidthDown } from "@material-ui/core";

import { FC } from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TermsPage from "./Terms&Condition";
import PrivacyPolicy from "./PrivacyPolicy";
import CountUs from "./ContactUs";
const App: FC<WithWidth> = ({ width }) => {
  const isMidDown = isWidthDown("md", width);

  if (isMidDown) {
    return (
      <>
      <Router>
        <Switch>
          <Route path="/terms&conditions" exact={true} component={TermsPage} />
          <Route path="/privacyPolicy" exact={true} component={PrivacyPolicy} />
          <Route path="/contactUs" exact={true} component={CountUs} />
          <Route component={Mobile} />
        </Switch>
      </Router>
      </>
    )
  }

  return (
    <>
      <Router>
        <Switch>
          <Route path="/terms&conditions" exact={true} component={TermsPage} />
          <Route path="/privacyPolicy" exact={true} component={PrivacyPolicy} />
          <Route path="/contactUs" exact={true} component={CountUs} />
          <Route component={Desktop} />
        </Switch>
      </Router>
    </>
  )
};

export default withWidth()(App);
