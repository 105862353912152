import React, { FC } from "react";

import Button from "@material-ui/core/Button";
import "../../App.css";

import { LOGIN_URL, REGISTER_URL } from "../../links";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

interface INavigationProps {}

const Navigation: FC<INavigationProps> = (props) => {
  return (
    <>
      <div
        className="navigation"
      >
        <Button
          variant="contained"
          color="primary"
          component="a"
          href={LOGIN_URL}
        >
          log in
        </Button>

        <Button
          component="a"
          href={REGISTER_URL}
          variant="contained"
          color="secondary"
        >
          create account
        </Button>
      </div>

      <Box
        display="flex"
        alignItems="center"
        position="fixed"
        zIndex={99999}
        p={2}
        bottom={4}
        right={0}
      >
        <IconButton style={{ color: "#e9c05d" }}>
          <HelpIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default Navigation;
