import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import footerImage from "../../images/footerimage.png";

import Instagram from "../../images/instagram.svg";
import Facebok from "../../images/facebook.svg";
import Twitter from "../../images/twitter.svg";
import { Link } from "react-router-dom";
interface IFooterProps { }

const Footer: FC<IFooterProps> = (props) => {
  return (
    <>
      <Box paddingBottom="350px" bgcolor="#1b1c4d" />
      <Box
        style={{
          marginTop: -100,
          backgroundImage: `url(${footerImage})`,
          backgroundSize: "cover",
          height: 50,
          backgroundRepeat: "no-repeat",
        }}
      />

      <Box bgcolor="#02162b" height={100}>
        <Box
          px={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography color="textSecondary" variant="body2">
            © 2020 StarFarm, LLC.
          </Typography>

          <a
            style={{ marginLeft: 16 }}
            href="https://www.instagram.com/starfarmmusic/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              height={28}
              width={28}
              src={Instagram}
              alt="starfarm-instagram"
            />
          </a>

          <a
            style={{ marginLeft: 16 }}
            href="https://www.facebook.com/starfarmmusic"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height={28} width={28} src={Facebok} alt="starfarm-facebook" />
          </a>

          <a
            style={{ marginLeft: 16 }}
            href="https://twitter.com/StarFarm10"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height={28} width={28} src={Twitter} alt="starfarm-twitter" />
          </a>
        </Box>

        <Box px={2} display="flex" alignItems="center" pb={2}>
          <Typography
            style={{ marginRight: 16 }}
            color="textSecondary"
            variant="body2"
          >
            <Link to="/terms&conditions" className="footerLink">
              Terms & Conditions
            </Link>
          </Typography>

          <Typography color="textSecondary" variant="body2" style={{ marginRight: 16 }}>
            <Link to="/privacyPolicy" className="footerLink">
              Privacy Policy
            </Link>
          </Typography>

          <Typography color="textSecondary" variant="body2">
            <Link to="/contactUs" className="footerLink">
              Contact Us
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
