import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import footerImage from "../../images/footerimage.png";
import { facebookHref, twitterHref, instagramHref } from "../../links";
import Instagram from "../../images/instagram.svg";
import Facebok from "../../images/facebook.svg";
import Twitter from "../../images/twitter.svg";
import SocialLink from "./SocialLinks";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

interface IFooterProps { }

const Footer: FC<IFooterProps> = (props) => {
  return (
    <>
      <Box
        style={{
          marginTop: 100,
          backgroundImage: `url(${footerImage})`,
          backgroundSize: "cover",
          height: 50,
          backgroundRepeat: "no-repeat",
        }}
      />

      <Box
        justifyContent="flex-end"
        alignItems="flex-end"
        display="flex"
        bgcolor="#02162b"
        height={100}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pr={8}
          pb={3}
          width={700}
        >
          <Typography color="textSecondary" variant="body2">
            <Link to="/terms&conditions" className="footerLink">
              Terms & Conditions
            </Link>
          </Typography>

          <Typography color="textSecondary" variant="body2">
            <Link to="/privacyPolicy" className="footerLink">
              Privacy Policy
            </Link>
          </Typography>

          <Typography color="textSecondary" variant="body2">
            <Link to="/contactUs" className="footerLink">
              Contact us
            </Link>
          </Typography>

          <Typography color="textSecondary" variant="body2">
            © 2020 StarFarm, LLC.
          </Typography>

          <SocialLink href={facebookHref} imgSrc={Facebok} />

          <SocialLink href={instagramHref} imgSrc={Instagram} />

          <SocialLink href={twitterHref} imgSrc={Twitter} />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
