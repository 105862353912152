import React from "react"
import "./App.css"
import Navigation from "./components/Desktop/Navigation";
import starfarmLogoUri from "./images/starfarmlogo.png";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";


const TermsPage = () => {
    window.scrollTo(0, 0)
    return (
        <>
            <Link to="/">
                <Box
                    position="absolute"
                    top={16}
                    width="100%"
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                >
                    <img width={35} height={35} src={starfarmLogoUri} alt="logo" />
                    <Typography variant="h5">StarFarm</Typography>
                </Box>
            </Link>
            <Navigation />
            <div className="termsContainer">
                <h2>
                    STARFARM TERMS AND CONDITIONS OF USE
                </h2>
                <p>
                    Effective as of March 26, 2021
                    By visiting, accessing, or using STARFARM, you signify that you have the right,
                    authority and capacity to enter into this Agreement, that you have read and
                    understand this Agreement, and that YOU AGREE TO ABIDE BY ALL OF THE
                    TERMS AND CONDITIONS OF THIS AGREEMENT, INCLUDING AUTORENEWALS
                    OF YOUR SUBSCRIPTION AND PROVISIONS GOVERNING
                    DISPUTE RESOLUTION. PLEASE READ THESE TERMS CAREFULLY. IF YOU
                    DO NOT AGREE, DO NOT ACCESS OR USE THE SERVICES.
                    ARTISTS, YOU REPRESENT AND PROMISE TO US THAT THE USER CONTENT
                    SUBMITTED BY YOU ON OR THROUGH THE SERVICES: (I) IS OWNED BY
                    YOU OR YOU OTHERWISE HAVE THE RIGHT TO GRANT THE LICENSE SET
                    FORTH HEREIN; (II) DOES NOT INFRINGE THE RIGHTS OF ANY THIRD PARTY,
                    INCLUDING ANY INTELLECTUAL PROPERTY RIGHTS, RIGHTS OF
                    CONFIDENTIAL INFORMATION OR RIGHTS IN PRIVACY; (III) DOES NOT IMPLY
                    ANY AFFILIATION, ENDORSEMENT, APPROVAL OR COOPERATION BY
                    STARFARM OR ANY ARTIST, BAND, LABEL, ENTITY OR INDIVIDUAL WITHOUT
                    EXPRESS WRITTEN CONSENT FROM SUCH INDIVIDUAL OR ENTITY; AND (IV)
                    SHALL COMPLY WITH ALL APPLICABLE LAWS AND THESE TERMS. YOU
                    AGREE TO PAY FOR ALL ROYALTIES, FEES, AND ANY OTHER MONIES
                    OWING ANY PERSON OR ENTITY BY REASON OF ANY USER CONTENT
                    SUBMITTED BY YOU ON OR THROUGH THE SERVICES.
                </p>
                <h3 className="termsHeading">
                    1. INTRODUCTION
                </h3>
                <p>

                    This document (the “Terms”) together with the Privacy Policy (collectively the
                    “Agreement”) sets out the terms and conditions governing visits, access and
                    use of the music service StarFarm by the end user (“you”). The term “you”
                    includes additional registered users whenever permitted under the applicable
                    subscription, visitors, and others who access or use any of the Services.
                    The “Services” means the music service branded StarFarm, mobile and device
                    applications and all services provided by StarFarm or StarFarm’s vendors, or
                    other third parties with whom StarFarm (as defined herein) contracts to provide
                    the Services that are inherently related to the music service StarFarm or that are
                    compatible for similarly situated digital music services. These may include, but
                    are not limited to websites and applications for desktops, tablets and mobile
                    handsets, set-top boxes and stereo equipment. The Services also include your
                    ability to edit certain Service Content (as that term is defined in Section 8,
                    below) that is specifically cleared for such use (the “Editable Content”) and User
                    Content (as that term is defined in Section 9, below), and save such edited
                    Editable Content and User Content to your own playlist without the ability to
                    share or otherwise reproduce, distribute, publicly perform, or publicly display
                    such edited Editable Content and User Content (the “Edit Functionality”). Each
                    instance of edited Editable Content or edited User Content shall be deemed a
                    “Remix” hereunder.
                    The Services are provided by STARFARM LLC and their affiliated entities, and
                    each of their present, former and future parents, predecessors, successors,
                    assignees, subsidiaries, owners, officers and directors (whether acting in such
                    capacity or individually), agents and attorneys (collectively, “StarFarm,” “we”
                    “us” and/or “our”). You may contact us at: StarFarm – Customer Support, 3723
                    Greenville Avenue Dallas, Texas 75205, or by emailing us
                    at support@StarFarm.com.
                    The current Agreement will appear on our website with the effective date that
                    the Terms and/or Privacy Policy were updated. StarFarm may, in its discretion,
                    make changes to the Agreement. When we make material changes to the
                    Agreement, we will provide you with notice as appropriate under the
                    circumstances, for example, by sending you an email. Your continued use of the
                    Services after the changes have been made will constitute your acceptance of
                    the changes. Please therefore make sure you read any such notice carefully. If
                    you do not wish to continue using the Services under the new version of the
                    Agreement, you may terminate the Agreement by contacting us at
                    support@StarFarm.com.
                </p>
                <h3 className="termsHeading">

                    2. PREREQUISITES FOR ACCESS TO THE SERVICE
                </h3>
                <p>

                    You need access to the internet in order to use the Services, and any cost for
                    accessing the internet shall be paid by you. This also applies when using the
                    Service via mobile phone, tablet, or other personal device capable of internet
                    connection. If you use the Services via mobile telephone you should familiarize
                    yourself with applicable data transfer costs for your mobile carrier plan.
                    StarFarm does not have any responsibility or liability for data transfer costs you
                    may incur when using the Services. We cannot guarantee that the Services will
                    work with all devices. StarFarm accepts no responsibility for any lack of
                    functionality that is due to your equipment (including your device, internet
                    connection, operating system or settings and software). Access to parts or all of
                    the Services may be restricted from time to time to allow for repairs,
                    maintenance or updating.
                    You may not use the Services in any way that is fraudulent or illegal or is
                    expressly prohibited by these Terms. By using the Services, you promise to us
                    that you accept these Terms and that
                    <ul className="outerList">
                        <li>
                            (a) You are 18 years of age or over
                        </li>
                        <li>
                            (b)
                            You are between the age of 13 and 17, and have express permission from your
                            parent or guardian to use the Services. If you are under age 13, you are not
                            permitted to use the Services or to submit any personally identifiable information
                            to StarFarm. If StarFarm reasonably believes that you have not told us your true
                            age, we may suspend your use of the Services until you have provided us with
                            acceptable proof of age. If you are a parent or guardian and believe StarFarm
                            may have inadvertently collected personal information from your child, please
                            notify StarFarm immediately by sending an email to StarFarm – Customer
                            Support at support@StarFarm.com detailing the basis of your belief and your
                            request.
                        </li>
                    </ul>
                    The Service requires you to register and create an account for validation. You
                    agree to

                    <ul className="outerList">
                        <li>
                            (a) Provide accurate, current and complete information as prompted by
                            any registration forms on the Services;
                        </li>
                        <li>

                            (b) Maintain and promptly update such
                            information and any other information provided to StarFarm, to keep it accurate,
                            current and complete;
                        </li>
                        <li>
                            (c) Maintain the security of your password and
                            identification;
                        </li>
                        <li>
                            (d) Be fully responsible for all use of your account and for any
                            actions that take place using your account.
                            StarFarm reserves the right to suspend or remove your account or prohibit your
                            use of the Services, without responsibility or liability to you, if activities occur on
                            your account that we believe breach the Agreement.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">
                    3. PURCHASE, PRICE INFORMATION, PAYMENT TERMS AND REFUND POLICY
                </h3>
                <p>
                    <ul className="outerList">
                        <li>
                            (a) Subscription. Certain aspects of the Services provided by StarFarm, third
                            parties, or both may require you to purchase a subscription for a period of time
                            as specified during sign-up (collectively a “Subscription”). Your Subscription,
                            which may start with a thirty (30) day trial period, will automatically renew on a
                            monthly or annual basis (as specified during your sign-up) unless you cancel
                            your Subscription as provided in Sections 3(e) and 3(g), or we terminate your
                            Subscription as provided in Section 18.
                        </li>
                        <li>
                            (b) Bundles. StarFarm may contract with a third party telecommunication
                            provider to provide the Services to you through a bundle package together with
                            other services provided by such third party provider. The payment terms for
                            such bundle package, as well as other terms and conditions governing your use
                            of the bundle package, may differ from the terms set out in this Agreement. You
                            are solely responsible for reviewing such bundle terms or any other terms
                            governing your use of the services provided by your telecommunication provider
                            before agreeing to any bundle package and accessing the Service via such
                            bundle package. If you have purchased a bundle package, payment will be
                            taken from you by your telecommunication provider. Should you wish to cancel
                            or terminate your subscription via such bundle package or obtain any further
                            information in respect of payment methods, billing cycles, automatic renewals or
                            refunds you must contact your provider. Other than this sub-section (b), Section
                            3 of this Agreement does not apply to you if you have purchased the Services as
                            part of a bundle package from your telecommunication provider or any other
                            third party.
                        </li>
                        <li>
                            (c) Promotion/Trials. StarFarm, vendors, or other third parties with whom we
                            contract to provide the Services may offer special promotions/trials with differing
                            conditions and limitations which may be subject to different terms from these
                            Terms. Any additional terms to these Terms will be disclosed at sign-up or in
                            other communications made available to you prior to sign-up. You are solely
                            responsible for reviewing any additional terms governing your use of such
                            special promotions or trials before accessing any special promotions or trials. At
                            the end of the free or promotional trial period, your subscription will
                            automatically renew for further rolling subscription periods of such duration as
                            set out in the product details when you signed up for the trial or promotion and
                            you will start to pay for the subscription. To avoid any charges, you must cancel
                            before the end of the free trial. You are entitled to only one free trial per lifetime.
                            Any attempt to utilize more than one free trial is an unauthorized use of the
                            Services and/or Service Content and amounts to a breach of the Agreement. If
                            StarFarm determines, in its absolute discretion, that you have utilized more than
                            one free trial, StarFarm reserves the right to terminate your access to the
                            Services, Service Content, and/or the Agreement.
                        </li>
                        <li>
                            (d) Shopping in the Store. It is not necessary to buy a Subscription to make a
                            purchase at the StarFarm Store. To make a store purchase, you will be
                            prompted to sign in to your account. If you do not have an account, create a free
                            account and choose a username and password. Upon completion of the Store
                            purchase, the purchased music or album will appear in your account for
                            download. You can download StarFarm Store purchases on one device only.
                            Once downloaded, your in-store purchase will no longer be available in your
                            account. All shopping in the Store is final. You are responsible for backing up
                            each purchase in the StarFarm Store
                        </li>
                        <li>
                            (e) Payment Methods. In connection with any and all Subscriptions that you
                            select to purchase you must provide StarFarm with a current, valid, accepted
                            method of payment, which may change from time to time ("Payment Method").
                            All Subscription purchases are subject to this Agreement and any additional
                            terms and conditions, including any fees, imposed by your financial services
                            provider and/or mobile carrier, as applicable. You are solely responsible for
                            reviewing the terms of use, privacy policy or any other terms governing your use
                            of the services provided by your financial services provider and/or mobile carrier.
                            By providing a Payment Method in connection with any Subscription, you
                            hereby authorize StarFarm to debit the total cost of said Subscription and agree
                            to be responsible for any foreign transaction charges associated with your
                            Subscription purchase. Should charges for which you are responsible fail at the
                            time payment is required, you may be responsible for costs associated with
                            StarFarm’s efforts to collect amounts due in accordance with applicable laws.
                            You represent and promise to us that all information you provide in connection
                            with the purchase of a Subscription is true, accurate and complete. The duration
                            and cost of Subscriptions are set by StarFarm in its sole discretion. When you
                            purchase a Subscription, you will initially be charged at the rate applicable at the
                            time of your initial agreement to subscribe. If the price of the Subscription
                            increases later, we will provide you with written notice before the end of the
                            billing period in which the change is made. If you do not accept the change to
                            your Subscription, you will be entitled to terminate this Agreement.
                        </li>
                        <li>
                            (f) Billing Cycle. As indicated during sign-up, the fee for certain Subscriptions
                            must be paid in a single upfront payment while other Subscriptions may allow
                            payment on an installment basis. If the Subscription fee is paid in a single
                            upfront payment, the total Subscription fee is due and payable to StarFarm
                            immediately upon sale and your Payment Method will be automatically billed at
                            that time. If the Subscription fee is paid on an installment basis, your Payment
                            Method will automatically be billed at the beginning of each installment period
                            as specified during sign-up.
                        </li>
                        <li>
                            (g) Automatic Renewal. In order to provide continuous service, you agree that
                            StarFarm will automatically renew a Subscription on or about the date the
                            Subscription expires. Such renewal will be for the same duration of the original
                            subscription term and will be billed at the non-discounted rate for the
                            Subscription as of the date of your enrollment and according to your original
                            billing cycle method (i.e. either as a single upfront payment or an installment
                            basis). By enrolling in a Subscription, you agree that your Subscription will be
                            subject to the above-described automatic renewals unless you cancel your
                            Subscription in accordance with the terms of this Agreement. You also agree
                            that the amount billed may be different if your original Subscription was
                            purchased using a promotional/trial offer and you authorize StarFarm to charge
                            your Payment Method for such varying amounts as indicated at the time of signup.
                            You agree that you are responsible for other charges, including any foreign
                            transaction charges, that may be imposed by credit card providers, payment
                            processors or other third parties in connection with your use of the Subscription.
                            Your Subscription will remain in effect and continue to renew automatically until
                            it is cancelled.
                            IF YOUR ACCOUNT IS MANAGED THROUGH StarFarm (AND NOT THROUGH
                            A THIRD PARTY) AND YOU WISH TO CANCEL YOUR AUTOMATIC
                            SUBSCRIPTION RENEWAL, you may do so by visiting your account settings
                            page. StarFarm will make reasonable efforts to process cancellation requests
                            within two business days from the receipt of the cancellation request. All other
                            cancellations are required to be made directly through the third party that
                            manages your StarFarm Subscription.
                        </li>
                        <li>
                            (h) Refund Policy. We will not refund any fees paid to us, including any advance
                            charge(s) or payment(s) for any subscription term for the Services, unless
                            otherwise required by law or the terms of this Agreement.
                        </li>
                        <li>
                            (i) Charge Backs. If you dispute Subscription charges with your credit card
                            company or financial institution, and StarFarm’s investigation determines the
                            charges were valid, we may, on reasonable written notice to you, charge to your
                            StarFarm account the reasonable required cost of responding to your charge
                            back request. StarFarm’s U.S. Privacy Policy details what information StarFarm
                            may share with your financial institution as part of the investigation into disputed
                            Subscription charges.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">
                    4. SPECIAL OFFERS
                </h3>
                <p>

                    If you have received or purchased access to the Services as part of a special
                    offer, campaign or a partner deal (a “Special Offer”), then the terms presented as
                    part of such Special Offer are a part of this Agreement. Special Offers will,
                    unless otherwise specified, only be valid for customers in the country (and
                    state(s)) where it is issued.
                </p>
                <h3 className="termsHeading">

                    5. RESTRICTIONS APPLICABLE TO THE USE OF THE SERVICE
                </h3>
                <p>

                    The Services shall be for private use only and use of the Services in commercial
                    or public settings is not permitted.
                    The Services shall not be used in any manner that may breach the restrictions
                    applicable to the utilization of the Service Content (as defined in Section 8,
                    below). These include, but are not limited to, prohibitions against
                    <ul className="outerList">
                        <li>
                            (i) Any form of
                            technical intervention in the Services for purposes of using the Services in any
                            manner other than that intended and permitted under the Agreement;
                        </li>
                        <li>
                            (ii) Copying
                            and/or making public any Service Content;
                        </li>
                        <li>
                            (iii) Assigning any username or
                            password to anyone else;
                        </li>
                        <li>
                            (iv) Circumventing any technical mechanisms within
                            the Services that have been established to protect Service Content from
                            reproduction/distribution;
                        </li>
                        <li>

                            (v) Using the Services in violation of applicable law;

                        </li>
                        <li>
                            (vi) Failing to respect applicable geographic/territorial restrictions.
                            The Services may include components that make use of temporary storage of
                            certain Service Content on the various platforms (also called “offline mode”).
                            You have access to this Service Content for as long as you have a valid
                            Subscription. You are not permitted to gain access to such Service Content via
                            other mechanisms than those available via the Services. Such Service Content
                            shall not be copied and/or made available outside the Services.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    6. APPLICATION LICENSE
                </h3>
                <p>

                    By “Application” we mean an application or other technical interface provided
                    by StarFarm that is used to access the Services. Subject to the terms of this
                    Agreement, StarFarm hereby grants you a personal, non-exclusive, nontransferable,
                    limited and revocable license to download, run and use the
                    Application for personal use on relevant devices owned or controlled by you.
                    Such license also applies to updates and upgrades that replace or supplement
                    the Services in any respect and which are not distributed with a separate license
                    and related documentation. The Application is licensed, not sold, to you, and
                    StarFarm retains ownership of all copies of the Application even after installation
                    on your devices. StarFarm may transfer its rights and obligations, or subcontract
                    or sub-license its obligations, under this Agreement to another legal
                    entity, provided your legal and consumer rights are not prejudiced. You may not
                    transfer your rights and obligations, or sub-contract or sub-license your
                    obligations, under this Agreement to another legal entity.
                </p>

                <h3 className="termsHeading">

                    7. THIRD PARTY APPLICATIONS
                </h3>

                <p>

                    The Services provides a platform for third party applications, websites, platforms
                    and services to make available products and services to you (“Third Party
                    Applications”), and your use of these Third Party Applications is subject to their
                    respective terms of use and privacy policies. You should contact the
                    administrator or webmaster for those Third Party Application if you have any
                    concerns regarding such Third Party Application or any content located on such
                    Third Party Application. You understand and agree that StarFarm is not
                    responsible or liable for the behavior, features, or content of any Third Party
                    Application and does not make any representations regarding the behavior,
                    features, content or accuracy of materials on any Third Party Application. You
                    should take precautions when downloading files from all third party applications
                    and websites to protect your computer / device from viruses and other
                    destructive programs. If you decide to access linked Third Party Applications,
                    you do so at your own risk.
                </p>
                <h3 className="termsHeading">

                    8. CONTENT RESTRICTIONS
                </h3>
                <p>

                    The Services contains content, such as sound recordings, audiovisual works,
                    other video or audio works, clips, images, graphics, text, software, works of
                    authorship, files, documents, applications, artwork, trademarks, trade names,
                    metadata, album titles, sound recording titles, artist names, intellectual property,
                    or materials relating thereto or any other materials, and their selection,
                    coordination and arrangement (collectively, the “Service Content”). The Service
                    Content is the property of StarFarm and/or third parties and is protected by
                    copyright under both United States and foreign laws. The Service Content is
                    made available to you only for your personal use as expressly permitted under
                    these Terms.
                    You are prohibited from using the Service Content as follows:
                    <ul className="innerList">
                        <li>
                            Except as permitted under the Terms, all copying, distribution or other
                            use of the Service Content is prohibited without the prior written consent
                            of the Service Content rights holders.
                        </li>
                        <li>
                            You have no right to engage in the commercial use, sale, resale,
                            assignment, license, modification, making of copies or derivative
                            versions, display, distribution or promotion of the Service Content
                            provided that to the extent expressly permitted by StarFarm, you may
                            make derivative versions of the Editable Content for use solely in
                            connection with the Edit Functionality.
                        </li>
                        <li>
                            You have no right to any use that requires a public performance license.
                        </li>
                        <li>

                            The use or posting of the Service Content on any other website,
                            application or otherwise, or sharing of the Service Content in a networked
                            computer environment, for any purpose is expressly prohibited.
                        </li>
                        <li>

                            You shall only use the Service Content on platforms which are at your
                            disposal for your personal use as provided herein and pursuant to your
                            Subscription terms, and which support the Services’ technical protection
                            system. You shall not circumvent or try to circumvent the Services’
                            technical protection system.
                        </li>
                        <li>

                            The Service Content is, with few exceptions such as content labeled
                            “created by StarFarm,” automatically uploaded to the platform by third
                            parties such as record labels or their distributors. StarFarm does not
                            review the Service Content supplied by third parties. The fact that Service
                            Content supplied by third parties is available on the platform therefore
                            does not imply any endorsement or approval by StarFarm. You
                            understand that StarFarm is not responsible for features or the specific
                            content of any Service Content supplied by third parties.
                        </li>
                        <li>

                            Unauthorized use of the Services may also constitute a violation of
                            applicable laws or regulations. If StarFarm, in its sole discretion, thinks
                            you have breached the Terms, or has reasonable grounds to believe that
                            you are likely to breach these Terms, we may take any action we think is
                            necessary to protect the Services and the Service Content. We may: (a)
                            restrict, suspend, or terminate your right to use the Services; (b) remove
                            your User Content (as defined below) from the Services; (c) investigate
                            and take legal proceedings against you; or (d) disclose any information to
                            law enforcement authorities we think is necessary or as required by law.
                            These actions are not limited and we may take any other action we
                            reasonably deem appropriate.
                        </li>
                        <li>

                            We are the owner of the StarFarm trademark (the “Trademark”). Nothing
                            in this Agreement or on the Services should be construed as granting, by
                            implication, estoppel, or otherwise, any license or right to use the
                            Trademark, without StarFarm’s prior written permission specific for each
                            such use. Use of the Trademark as part of a link to or from any site is
                            prohibited unless establishment of such a link is approved in advance by
                            StarFarm in writing. All goodwill generated from the use of the Trademark
                            inures to StarFarm’s benefit.
                        </li>
                        <li>
                            Elements of the Services including Service Content are protected by
                            copyright, trade dress, trademark, unfair competition, or other state,
                            federal and foreign laws, and the Services may not be copied or imitated
                            in whole or in part, by any means, including but not limited to the use of
                            framing or mirrors. None of the Service Content may be retransmitted
                            without StarFarm’s and/or the appropriate third party’s express written
                            consent in each and every instance.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    9. USER CONTENT
                </h3>
                <p>

                    To the extent allowed by the Services, any musical works (sound recordings and
                    underlying musical compositions), audiovisual works (including but not limited to
                    MTV style premium music videos, clips and so called “behind the scenes”
                    audiovisual content), other video or audio works, images, graphics, text, works
                    of authorship, files, documents, applications, artwork, trademarks, trade names,
                    metadata, album titles, sound recording titles, artist names, intellectual property,
                    or materials relating thereto or any other materials that you submit to the Service
                    (“User Content”) are generated, owned and controlled solely by you and/or your
                    licensees. We do not claim any intellectual property ownership rights in any User
                    Content. After directly sending (“submitting”) your User Content to the Services,
                    you continue to retain any intellectual property ownership rights that you may
                    have in your User Content, subject to the license below. By submitting any User
                    Content on or through the Services, you hereby grant to StarFarm a license (with
                    the unrestricted right to sublicense) to store, host, display, stream, transmit
                    conditional downloads, exploit, promote, market, sell and transmit permanent
                    downloads, use, modify, delete from, add to, publicly perform, publicly display,
                    reproduce, create and use derivative works of, distribute and otherwise
                    disseminate the User Content on or through the Services via any and all devices
                    known or hereinafter devised.
                    <ul className="outerList">
                        <li>
                            (a) The license you grant to StarFarm is non-exclusive (meaning you are free to
                            license User Content to anyone else), fully-paid and royalty-free (meaning that
                            we are not required to pay you for the use on the Services of the User Content
                            that you post), sub-licensable (so that we are able to use our affiliates,
                            subcontractors and other vendors such as internet content delivery networks
                            and wireless carriers to provide the services related to the Services), perpetual
                            (forever) and worldwide.
                        </li>
                        <li>
                            (b) YOU REPRESENT AND PROMISE TO US THAT THE USER CONTENT
                            SUBMITTED BY YOU ON OR THROUGH THE SERVICES: (I) IS OWNED BY
                            YOU OR YOU OTHERWISE HAVE THE RIGHT TO GRANT THE LICENSE SET
                            FORTH HEREIN; (II) DOES NOT INFRINGE THE RIGHTS OF ANY THIRD PARTY,
                            INCLUDING ANY INTELLECTUAL PROPERTY RIGHTS, RIGHTS OF
                            CONFIDENTIAL INFORMATION OR RIGHTS IN PRIVACY; (III) DOES NOT IMPLY
                            ANY AFFILIATION, ENDORSEMENT, APPROVAL OR COOPERATION BY
                            STARFARM OR ANY ARTIST, BAND, LABEL, ENTITY OR INDIVIDUAL WITHOUT
                            EXPRESS WRITTEN CONSENT FROM SUCH INDIVIDUAL OR ENTITY; AND (IV)
                            SHALL COMPLY WITH ALL APPLICABLE LAWS AND THESE TERMS. YOU
                            AGREE TO PAY FOR ALL ROYALTIES, FEES, AND ANY OTHER MONIES
                            OWING ANY PERSON OR ENTITY BY REASON OF ANY USER CONTENT
                            SUBMITTED BY YOU ON OR THROUGH THE SERVICES.
                        </li>
                        <li>
                            (c) The Services perform technical functions necessary to offer the services on
                            the Services, including but not limited to transcoding and/or reformatting User
                            Content to allow its use throughout the Services.
                        </li>
                        <li>
                            (d) We may reject, refuse to post or delete any User Content for any or no
                            reason, including User Content that in our sole judgment breaches this
                            Agreement or which may be offensive, illegal or breach the rights of any third
                            party, or harm or threaten the safety of any third party. We are under no
                            obligation to you or any other person to oversee, monitor or moderate User
                            Content. We may at our option, moderate and review User Content to ensure
                            that it complies with these Terms. If we do opt to monitor the Services, we
                            assume no responsibility or liability to you for any User Content available on the
                            Services.
                        </li>
                        <li>
                            (e) The licenses granted in this section are granted separately with respect to
                            each item of the User Content that you submit to the Services.
                        </li>
                        <li>
                            (f) You are solely responsible for the User Content that you submit to the
                            Services. You must not upload, store, distribute, send, transmit, display,
                            perform, make available or otherwise communicate to the public any content to
                            which you do not hold the necessary rights. In particular, any unauthorized use
                            of copyright protected material within the User Content (including by way of
                            reproduction, distribution, modification, adaptation, public display, public
                            performance, preparation of derivative works, making available or otherwise
                            communicating to the public via the Services) may constitute an infringement of
                            third party rights and is strictly prohibited. Any such infringements and may also
                            result in civil litigation or criminal prosecution by or on behalf of the relevant
                            rights holder.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    10. PROHIBITED CONTENT / ACTIVITY
                </h3>
                <p>

                    You must not upload or share any User Content which, in StarFarm’s reasonable
                    opinion:
                    <ul className="innerList">
                        <li>
                            Is patently offensive and promotes racism, bigotry, hatred or physical
                            harm of any kind against any group or individual;
                        </li>
                        <li>
                            Harasses or advocates harassment of another person;
                        </li>
                        <li>
                            Exploits people in a sexual or violent manner;
                        </li>
                        <li>
                            Contains nudity, excessive violence, or offensive subject matter or
                            contains a link to an adult website;
                        </li>
                        <li>
                            Solicits personal information;
                        </li>
                        <li>
                            Constitutes information that poses or creates a privacy or security risk to
                            any person;
                        </li>
                        <li>
                            Constitutes or promotes information that you know is false or misleading
                            or promotes illegal activities or conduct that is abusive, threatening,
                            obscene, defamatory or libelous;
                        </li>
                        <li>
                            Constitutes or promotes an illegal or unauthorized copy of another
                            person's copyrighted work, such as providing pirated computer
                            programs or links to them, providing information to circumvent
                            manufacturer-installed copy-protect devices, or providing pirated music
                            or links to pirated music files;
                        </li>
                        <li>
                            Involves the transmission of "junk mail," "chain letters," or unsolicited
                            mass mailing, instant messaging, "spimming," or "spamming";
                        </li>
                        <li>
                            Contains restricted or password-only access pages or hidden pages or
                            images (those not linked to or from another accessible page);
                        </li>
                        <li>
                            Furthers or promotes any criminal activity or enterprise or provides
                            instructional information about illegal activities including, but not limited
                            to making or buying illegal weapons, violating someone's privacy, or
                            providing or creating computer viruses;
                        </li>
                        <li>
                            Solicits passwords or personal identifying information for commercial or
                            unlawful purposes from other users;
                        </li>
                        <li>
                            Involves any form of user tracking, commercial activities and/or sales
                            without prior written consent from StarFarm such as contests,
                            sweepstakes, barter, advertising, or pyramid schemes; or
                        </li>
                        <li>
                            Breaches the privacy rights, publicity rights, copyrights, trademark rights,
                            contract rights or any other rights of any person.
                            You further agree not to undertake any of the following activities on the Services:
                        </li>
                        <li>
                            Criminal or tortious activity, including child pornography, fraud, trafficking
                            in obscene material, drug dealing, gambling, harassment, stalking,
                            spamming, spimming, sending of viruses or other harmful files, copyright
                            infringement, patent infringement, or theft of trade secrets;
                        </li>
                        <li>
                            Advertising to, or solicitation of, any user, without the written consent of
                            StarFarm to buy or sell any products or services through the
                            unauthorized or impermissible use of the Services;
                        </li>
                        <li>
                            Circumventing or modifying, attempting to circumvent or modify, or
                            encouraging or assisting any other person in circumventing or modifying
                            any security technology or software that is part of the Services;
                        </li>
                        <li>
                            Activity that involves the use of viruses, bots, worms, or any other
                            computer code, files or programs that interrupt, destroy or limit the
                            functionality of any computer software or hardware, or otherwise permit
                            the unauthorized use of or access to a computer or a computer network;
                        </li>
                        <li>
                            Covering or obscuring the banner advertisements on any page on the
                            Services via HTML/CSS or any other means;
                        </li>
                        <li>
                            Any automated use of the system, such as, but not limited to, using
                            scripts to submit information or send comments or messages;
                        </li>
                        <li>
                            Interfering with, disrupting, or creating an undue burden on the Services
                            or the networks or services connected to the Services;
                        </li>
                        <li>
                            Impersonating or attempting to impersonate another user, person or
                            entity;
                        </li>
                        <li>
                            Using the account, username, or password of another user at any time or
                            disclosing your password to any third party or permitting any third party
                            to access your account;
                        </li>
                        <li>
                            Selling or otherwise transferring your account;
                        </li>
                        <li>
                            Using any information obtained from the Services in order to harass,
                            abuse, or harm another person or entity, or attempting to do the same;
                        </li>
                        <li>
                            Accepting payment or anything of value from a third person in exchange
                            for your performing any commercial activity through the unauthorized or
                            impermissible use of the Services on behalf of that person; or
                        </li>
                        <li>
                            Using the Services in a manner inconsistent with any and all applicable
                            laws and regulations.
                            You agree that any breach of the provisions of this Section 10 will be considered
                            a serious breach of the Agreement.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    11. SUBMISSIONS
                </h3>
                <p>

                    We have provided our contact information and email links on the Services
                    because we would like to hear from you. However, we do not want you to, and
                    you should not, send StarFarm any documents or other materials that contain
                    confidential or proprietary information. You grant StarFarm an unrestricted,
                    irrevocable, royalty-free license to copy, distribute, and modify any such
                    documents or other materials that you send StarFarm, and to use for any
                    purpose any ideas, trade secrets, know-how or other confidential or proprietary
                    information disclosed in those documents or other materials, and that all of the
                    representations and promises you make pursuant to this Agreement with
                    respect to User Content apply equally with respect to any other documents or
                    materials that you send to StarFarm that do not constitute User Content.
                </p>
                <h3 className="termsHeading">

                    12. NOTICE AND PROCEDURES FOR MAKING CLAIMS OF COPYRIGHT OR
                </h3>
                <p>
                    INTELLECTUAL PROPERTY INFRINGEMENT
                    We, at our sole discretion, may disable and/or terminate use of the Services by
                    users who infringe the intellectual property rights of others. We also have a
                    policy of responding to notices of alleged infringement that complies with the
                    Digital Millennium Copyright Act (“DMCA”) which may include removing
                    material, including Service Content and/or User Content, claimed to be the
                    subject of infringing activity. If we remove material to comply with the DMCA
                    (“Removed Content”), we will attempt to contact the provider of such Removed
                    Content to enable them to make a counter notification pursuant to the DMCA.
                    We will terminate the accounts of any users and/or block access to the Services
                    by any users who repeatedly infringe the copyrights of others.
                    If you believe that your work has been copied in a way that constitutes copyright
                    infringement, or that your intellectual property or privacy rights have been
                    otherwise breached, please provide StarFarm with a notice containing the
                    following information:
                    <ul className="innerList">
                        <li>
                            An electronic or physical signature of the person authorized to act on
                            behalf of the owner of the copyright or other intellectual property interest;
                        </li>
                        <li>
                            A detailed description of the copyrighted work or other intellectual
                            property that you claim has been infringed;
                        </li>
                        <li>

                            A detailed description of where the material that you claim is infringing is
                            located on the website (providing URL(s) in the body of an email is the
                            best way to help StarFarm locate content quickly);
                        </li>
                        <li>

                            Your name, address, telephone number, and email address;
                        </li>
                        <li>

                            A statement by you that you have a good faith belief that the disputed
                            use is not authorized by the copyright owner, its agent, or the law;
                        </li>
                        <li>
                            A statement by you, made under penalty of perjury, that the above
                            information in your notice is accurate and that you are the copyright or
                            other intellectual property right owner or authorized to act on the
                            copyright or intellectual property right owner’s behalf; and
                        </li>
                        <li>
                            Your signature.
                            Send your notice to StarFarm Copyright Department, 3723 Greenville Avenue
                            Dallas, Texas 75206 or by email to support@StarFarm.com. Please note that you
                            may be liable for damages (including costs and attorneys’ fees) if you materially
                            misrepresent that any material is infringing your copyrights or other intellectual
                            property or privacy rights.
                            The provider of Removed Content may make a counter notification pursuant to
                            Sections 512(g)(2) and (3) of the DMCA. When we receive a counter notification,
                            we may reinstate the Removed Content. To submit a counter notification, you
                            must provide StarFarm with a written notice that includes each of the following
                            items:
                        </li>
                        <li>
                            A detailed identification of the material the StarFarm has removed;
                        </li>
                        <li>
                            Your name, address, telephone number, e-mail address, and a statement
                            that you consent to the jurisdiction of Federal District Court for the
                            judicial district in which your address is located (or New York, New York if
                            your address is outside of the United States), and that you will accept
                            service of process from the person who provided notification under
                            subsection (c)(1)(C) or an agent of such person;
                        </li>
                        <li>
                            The statement: "I swear, under penalty of perjury, that I have a good faith
                            belief that each search result or message identified above was removed
                            or disabled as a result of a mistake or misidentification of the material to
                            be removed or disabled.";
                        </li>
                        <li>

                            Your signature.
                            The counter notification can be sent to StarFarm Copyright Department, 540 W
                            26th Street, 8th Floor, New York, NY 10001 or by email to
                            support@StarFarm.com. Please note that you may be liable for damages
                            (including costs and attorneys’ fees) if you materially misrepresent that a
                            product or activity is not infringing the copyrights of others.
                            If you are the owner of any intellectual property other than copyrighted materials
                            (such as, for example, a trademark), or an agent thereof, and believe that any
                            item of content or other materials on the Services infringes upon your rights in
                            such intellectually property, you must submit a notification to us in the same
                            manner as provided above in respect of notice of alleged copyright infringement,
                            including providing the same information in writing to the designated address
                            set forth above.
                            DISCLAIMER: WE ARE NOT YOUR LEGAL ADVISORS, AND THE
                            INFORMATION WE PRESENT HERE IS NOT LEGAL ADVICE. WE PRESENT
                            THIS INFORMATION FOR INFORMATIONAL PURPOSES ONLY, AND AS
                            REQUIRED BY LAW.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    13. PRIVACY POLICY
                </h3>
                <p>

                    For U.S. residents, the U.S. Privacy Policy forms part of this Agreement and is
                    incorporated herein by reference. The Privacy Policy is located at: https://
                    starfarm.com/privacy_policy.
                    Any questions, complaints or claims regarding the Services can be directed to:
                    support@StarFarm.com
                </p>
                <h3 className="termsHeading">

                    14. NOTICE TO CALIFORNIA RESIDENTS
                </h3>
                <p>

                    Pursuant to California Civil Code Section 1789.3, California residents are entitled
                    to the following specific consumer rights notice which is hereby offered as a
                    convenience to you:
                    The name and address of the provider of the Services is StarFarm, LLC. –
                    Customer Support, 3723 Greenville Avenue Dallas, Texas 75206. Complaints
                    regarding the service or requests to receive further information regarding use of
                    this service may be sent to the above address or by email to
                    support@StarFarm.com.
                    The Complaint Assistance Unit of the Division of Consumer Services of the
                    California Department of Consumer Affairs may be contacted in writing at 1625
                    North Market Boulevard, Suite S202, Sacramento, CA 95834 or by telephone at
                    (916) 574-7950 or (800) 952-5210.
                </p>
                <h3 className="termsHeading">

                    15. THE RIGHTS OF STARFARM
                </h3>
                <p>

                    If you fail to make payment or materially breach any provision of the Agreement,
                    and such breach is not caused by circumstances on the part of StarFarm,
                    StarFarm may, taking the interest of the user in account, deny further access to
                    the Services.
                    You are aware that StarFarm may send Services-related information to you in
                    the form of e-mails and, where you have made your mobile number available
                    and provided consent, by text messages, as detailed further in the Privacy
                    Policy.
                </p>
                <h3 className="termsHeading">

                    16. NO WARRANTY/ LIMITATION OF LIABILITY
                </h3>
                <p>

                    The Services and the Service Content are provided on an “as is” and “as
                    available” basis without any warranties of any kind. To the fullest extent
                    permitted by applicable law, StarFarm disclaims all warranties, including, but not
                    limited to, the warranty of title, merchantability, non-infringement of third parties
                    rights, and fitness for particular purpose. StarFarm reserves the right to change
                    functionality and content offered under the Services on an ongoing basis.
                    To the fullest extent permitted by applicable law, StarFarm makes no warranties
                    or representations about the Services or Service Content, including but not
                    limited to their accuracy, reliability, completeness, efficacy, or timeliness.
                    StarFarm shall not be subject to liability for truth, accuracy, or completeness of
                    any information conveyed to the user or for errors, mistakes or omissions therein
                    or for any delays or interruptions of the data or information stream from
                    whatever cause. You agree that your use the Services and the Service Content
                    is at your own risk. It is your responsibility to evaluate the accuracy,
                    completeness or usefulness of any opinion, advice or other Service Content
                    available through the Services.
                    In no event shall StarFarm be liable for any damages whatsoever (including,
                    without limitation, incidental and consequential damages, lost profits, or
                    damages resulting from lost data or business interruption) resulting from the
                    access, use or inability to use the Services and the Service Content, whether
                    based on warranty, contract, tort (including negligence), or any other legal
                    theory, even if we have been advised of the possibility of such damages. Some
                    states do not allow exclusion of implied warranties or limitation of liability for
                    incidental or consequential damages, so the above limitations or exclusions may
                    not apply to you. In such states, our liability shall be limited to the greatest
                    extent permitted by law. To the fullest extent permitted by law, the liability of
                    StarFarm arising from any error or other circumstance that may give rise to
                    liability in relation to the Services shall – except for instances of any physical
                    harm, gross negligence and willful misconduct - be limited to the amount paid
                    by you for access to the Services in the relevant month.
                    Technical problems such as temporary non-availability of individual parts of the
                    Services or the short-term unavailability of the whole service itself must be
                    expected and shall not constitute breach of this Agreement. StarFarm does not
                    warrant that the Services will operate error-free or that the Services, its server, or
                    the Service Content are free of computer viruses or similar contamination or
                    destructive features. Updates to the Service may take place on a regular basis.
                    StarFarm reserves the right to add or remove support for specific devices. If
                    your access or use of the Services or the Service Content results in the need for
                    servicing or replacing equipment or data, StarFarm shall not be responsible for
                    those costs.
                    Some aspects of this Section may not apply in some jurisdictions if prohibited
                    by applicable law. However, no provision in these Terms shall apply to any
                    consumer in New Jersey if the provision limits redress for/under: (i) StarFarm’s
                    tortious actions (e.g., negligence, failure to exercise a basic standard of care,
                    failure to avoid creating an unreasonable risk of harm); (ii) the New Jersey
                    Products Liability Act, N.J.S.A. 2A:58C-1, et seq. (i.e., the statutorily imposed
                    duty to refrain from manufacturing and selling dangerous products, with the
                    possibility of punitive damages for violations thereof); (iii) the New Jersey
                    Punitive Damages Act, N.J.S.A. 2A:15-5.9, et seq. (i.e., the statutory right to
                    pursue punitive damages in the event of harm caused by actual malice, wanton
                    and willful disregard, reckless indifference); (iv) the New Jersey Uniform
                    Commercial Code (i.e., a comprehensive statutory regime governing the rights
                    and duties of buyers and sellers with respect to contracts for the sale of goods,
                    with the possibility of damages for economic and property harm); and (v)
                    StarFarm’s failure to reasonably protect against harm arising from certain
                    criminal acts of third parties (e.g., computer hacking and identity theft, as
                    regulated by the Federal Trade Commission and the Federal Communications
                    Commission, and as governed by the New Jersey Identity Theft Protection Act,
                    N.J.S.A.56:8-161, et seq., and the New Jersey Consumer Fraud Act, N.J.S.A.
                    56:8-3, et seq.). NEW JERSEY RESIDENTS: With respect to these Terms, the
                    provision concerning the exclusion or limitation of certain damages is not
                    applicable in New Jersey with respect to statutory damages, punitive damages,
                    loss of data, and loss of or damage to property.
                </p>

                <h3 className="termsHeading">

                    17. INDEMNIFICATION
                </h3>
                <p>

                    To the fullest extent permitted by applicable law, you agree to defend, indemnify,
                    and hold StarFarm harmless from and against any claims, actions or demands,
                    including, without limitation, reasonable legal and accounting fees, arising from,
                    relating to or resulting from
                    <ul className="outerList">
                        <li>

                            (a) Your breach or alleged breach of the Agreement;
                        </li>
                        <li>

                            (b) Your access to, use or misuse of the Service Content or the Services;
                        </li>
                        <li>

                            (c) Any
                            breach of your representations and promises set forth herein
                        </li>
                        <li>

                            (d) Your
                            violation of any law or the rights of a third party. We shall provide notice to you
                            of any such claim, suit, or proceeding and shall assist you, at your expense, in
                            defending any such claim, suit or proceeding. We reserve the right to assume
                            the exclusive defense and control of any matter that is subject to indemnification
                            under this Section. In such case, you agree to cooperate with any reasonable
                            requests assisting our defense of such matter.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    18. TERMINATION
                </h3>
                <p>

                    We may terminate this Agreement and close your account, or suspend your
                    access to the Services, at any time without notice and without responsibility or
                    liability to you, in the event of your actual or suspected unauthorized use or
                    misuse of the Services and/or Service Content, or breach of the Agreement. In
                    that event, we will refund to you a pro-rata share of your Subscription charge to
                    account for the period you will not be able to access the Services.
                    We may terminate this Agreement and close your account at any time on
                    reasonable notice to you if we cease to provide the Services.
                </p>
                <h3 className="termsHeading">

                    19. GOVERNING LAW; ARBITRATION OF DISPUTES
                </h3>
                <p>

                    Any dispute or claim arising out of or relating in any way to one or more of the
                    following:
                    <ul className="outerList">
                        <li>

                            (1) the Agreement;
                        </li>
                        <li>

                            (2) the Services or Service Content, including User
                            Content;
                        </li>
                        <li>

                            (3) your Subscription;
                        </li>
                        <li>

                            (4) any products or services sold or distributed
                            by StarFarm or through StarFarm (collectively “Claims”), will be resolved by
                            binding arbitration, rather than in court, except that you and StarFarm may
                            assert Claims on an individual basis in small claims court if they qualify. You and
                            StarFarm also agree that you or we may bring suit in court to enjoin infringement
                            or other misuse of intellectual property rights unrelated to your use of the
                            Services or Subscription.
                        </li>
                    </ul>
                    This Arbitration Provision is intended to be given the broadest possible meaning
                    under the law and shall be governed by the Federal Arbitration Act and
                    applicable U.S. federal arbitration law.
                    <ul className="outerList">
                        <li>
                            (a) Prohibition of Class and Representative Actions and Non-Individualized Relief
                            Any and all proceedings to resolve Claims will be conducted only on an
                            individual basis and not in a class, consolidated, or representative action or
                            proceeding. The arbitrator may award relief (including monetary, injunctive, and
                            declaratory relief) only in favor of the individual party seeking relief and only to
                            the extent necessary to provide relief necessitated by that party’s individual
                            claims. Any relief awarded cannot affect other users of the Services.
                        </li>
                        <li>
                            (b) Arbitration Procedures
                            There is no judge or jury in arbitration, and court review of an arbitration award is
                            limited. However, the arbitrator may award the same damages and relief as a
                            court (including statutory damages, attorneys’ fees and costs), except that the
                            arbitrator cannot not award any relief, including declaratory or injunctive relief,
                            benefiting anyone but the parties to the arbitration. The arbitrator must follow
                            and enforce the Agreement.
                            The arbitrator, and not any court, shall have exclusive authority to resolve any
                            Claim relating to the interpretation, applicability, enforceability or formation of
                            this Arbitration Provision including, but not limited to any claim that all or any
                            part of this Agreement is void or voidable. The arbitrator will decide any and all
                            Claims—statutory, common law, or otherwise.
                            The arbitration will be conducted by a neutral arbitrator from the American
                            Arbitration Association (“AAA”) in accordance with AAA’s rules and procedures,
                            including the AAA’s Consumer Arbitration Rules available at https://www.adr.org/
                            sites/default/files/document_repository/Consumer_Rules_Web_0.pdf. If there is
                            any inconsistency between the AAA rules and this Arbitration Provision, the
                            terms of this Arbitration Provision will control unless the arbitrator determines
                            that the application of the inconsistent Arbitration Provision terms would result in
                            a fundamentally unfair arbitration.
                            To commence arbitration, you must complete a short form, submit it to the AAA,
                            and send a copy to us, Attn: StarFarm, 3723 Greenville Avenue Dallas, Texas
                            75206. For more information, see the AAA’s claim filing page, https://
                            www.adr.org/. You may represent yourself in the arbitration or be represented by
                            an attorney or another representative. Once we receive your arbitration claim,
                            we may assert any counterclaims we may have against you.
                            The arbitration shall be held in Dallas, Texas, or at another mutually agreed
                            location.
                        </li>
                        <li>

                            (c) Costs of Arbitration
                            Payment of all filing, administration and arbitrator fees will be governed by the
                            AAA Consumer Arbitration Rules. You and StarFarm will be individually
                            responsible for all other costs and fees incurred in connection with the
                            arbitration, including without limitation, all attorneys’ fees, unless recoverable
                            under applicable law. However, in the event the arbitrator determines the
                            claim(s) you assert in the arbitration to be frivolous, you agree to reimburse us
                            for all fees and costs associated with the arbitration, including our attorneys’
                            fees, and fees and costs paid by us on your behalf that you otherwise would be
                            obligated to pay under the AAA’s rules.
                        </li>
                        <li>

                            (d) Severability
                            If the arbitrator decides that any term or provision of this Arbitration Provision
                            other than Section 19(a) (Prohibition of Class and Representative Actions and
                            Non-Individualized Relief) is invalid or unenforceable, you and StarFarm agree to
                            replace such term or provision with a term or provision that is valid and
                            enforceable and that comes closest to expressing the intention of the invalid or
                            unenforceable term or provision, and this Arbitration Provision shall be
                            enforceable as so modified. If the arbitrator decides that any of the provisions of
                            Section 19(a) is invalid or unenforceable, then the entirety of this Arbitration
                            Provision shall be null and void. The remainder of the Agreement will continue to
                            apply.
                            If for any reason Claims proceeds in court rather than in arbitration, you and
                            StarFarm waive any right to a jury trial. In that instance, you expressly agree to
                            submit to the exclusive personal jurisdiction of the state and federal courts
                            sitting in the State of Texas, County of Dallas.
                        </li>
                        <li>

                            (e) Future Changes to this Arbitration Provision
                            Notwithstanding any provision in this Agreement to the contrary, you and
                            StarFarm agree that if we make any change to this Arbitration Provision (other
                            than a change to any notice address or website link provided herein) in the
                            future, that change shall not apply to any claim that was filed in a legal
                            proceeding against StarFarm prior to the effective date of the change. Moreover,
                            if future version of this Agreement do not contain this or another Arbitration
                            Provision, any existing Arbitration dispute will not be affected.
                        </li>
                    </ul>
                </p>
                <h3 className="termsHeading">

                    20. MISCELLANEOUS
                </h3>
                <p>

                    There is no agency, partnership, joint venture, employee-employer or franchisorfranchisee
                    relationship between you and StarFarm or between StarFarm and any
                    other user of the Services. If any provision of this Agreement is found to be
                    invalid by any court having competent jurisdiction, the invalidity of such
                    provision shall not affect the validity of any other provision of the Agreement,
                    which shall remain in full force and effect unless such deletion materially
                    frustrates the intent of the parties, in which case this Agreement will terminate.
                    Our failure to act on or enforce any provision of this Agreement shall not be
                    construed as a waiver of that provision or any other provision in this Agreement.
                    No waiver shall be effective against StarFarm unless made in writing, and no
                    such waiver shall be construed as a waiver in any other or subsequent instance.
                    This Agreement is not intended to, and does not, give any person who is not a
                    party to it any right to enforce any of its provisions.
                    StarFarm intends to rely on this Agreement, including the applicable Privacy
                    Policy, and any notices regarding the Services sent to you or posted on the
                    Services. If you require any changes to the Agreement, such changes must be
                    expressly agreed to by you and StarFarm in writing. This will help avoid any
                    problems about what you expect from StarFarm and what StarFarm expects
                    from you.
                    The section headings are provided merely for convenience and shall not be
                    given any legal import. This Agreement will inure to the benefit of our
                    successors, assigns, licensees, and sublicensees.
                </p>
            </div>
        </>
    )
}

export default TermsPage